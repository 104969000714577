import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { ColumnComponent } from '../column.component';
import { SectionColumnTerpeneType } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { exists } from '../../../../../functions/exists';
import { StringUtils } from '../../../../../utils/string-utils';

@Component({
  selector: 'app-column-terpene',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgStyle,
    SharedModule,
  ],
  templateUrl: './column-terpene.component.html',
  styleUrl: './column-terpene.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnTerpeneComponent extends ColumnComponent implements AfterViewInit, OnChanges {

  constructor(protected renderer: Renderer2) {
    super();
  }

  @Input() useNgContent: boolean;
  @Input() terpeneColumnType: SectionColumnTerpeneType;
  @ViewChild('terpeneColumn') terpeneColumn: ElementRef<HTMLDivElement>;
  private currentTerpeneClass: string;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cannabinoidColumnType) this.updateClass();
  }

  private updateClass(): void {
    if (exists(this.terpeneColumn?.nativeElement)) {
      this.renderer.removeClass(this.terpeneColumn.nativeElement, this.currentTerpeneClass);
      this.currentTerpeneClass = `product-${StringUtils.pascalCaseToKebabCase(this.terpeneColumnType)}`;
      this.renderer.addClass(this.terpeneColumn.nativeElement, this.currentTerpeneClass);
    }
  }

}

