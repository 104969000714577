import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SplashComponent } from './components/splash/splash.component';
import { DisplayComponent } from './components/display/display.component';
import { SharedModule } from '../shared/shared.module';
import { DisplayRoutingModule } from './display-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProductMenuComponent } from './components/menus/product-menu/product-menu.component';
import { ProductMenuHeaderInflatorComponent } from './components/menus/product-menu/inflators/product-menu-header-inflator/product-menu-header-inflator.component';
import { ProductMenuFooterInflatorContainerDirective } from './components/menus/product-menu/inflators/product-menu-footer-inflator/product-menu-footer-inflator-container.directive';
import { ProductMenuSectionInflatorComponent } from './components/menus/product-menu/inflators/product-menu-section-inflator/product-menu-section-inflator.component';
import { ProductMenuFooterInflatorComponent } from './components/menus/product-menu/inflators/product-menu-footer-inflator/product-menu-footer-inflator.component';
import { ProductMenuFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { TitleSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/title-section/title-section.component';
import { MenuHeaderTitleImageComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { MenuHeaderPlainJaneComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-plain-jane/menu-header-plain-jane.component';
import { MenuHeaderTitleComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { MenuHeaderTitleImageWithBackgroundAssetComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image-with-background-asset/menu-header-title-image-with-background-asset.component';
import { MenuFooterOvalBoarderComponent } from './components/menus/product-menu/building-blocks/menu-footer/menu-footer-oval-boarder/menu-footer-oval-boarder.component';
import { AssetSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/image-section/asset-section.component';
import { CastToProductMenuPipe } from './pipes/cast-to-product-menu.pipe';
import { ProductMenuItemInflatorComponent } from './components/menus/product-menu/inflators/product-menu-item-inflator/product-menu-item-inflator.component';
import { MenuItemComponent } from './components/menus/product-menu/building-blocks/menu-item/menu-item.component';
import { ProductSectionItemComponent } from './components/menus/product-menu/building-blocks/menu-item/product-section-item/product-section-item.component';
import { ProductMenuSectionHeaderInflatorComponent } from './components/menus/product-menu/inflators/product-menu-section-header-inflator/product-menu-section-header-inflator.component';
import { ProductMenuSectionHeaderInflatorContainerDirective } from './components/menus/product-menu/inflators/product-menu-section-header-inflator/product-menu-section-header-inflator-container.directive';
import { ProductSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/product-section-header/product-section-header.component';
import { MenuHeaderPreRollComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-pre-roll/menu-header-pre-roll.component';
import { MarketingMenuInflatorComponent } from './components/menus/marketing-menu/inflators/marketing-menu-inflator/marketing-menu-inflator.component';
import { MarketingLoopComponent } from './components/menus/marketing-menu/marketing-loop/marketing-loop.component';
import { CastToMarketingMenuPipe } from './pipes/cast-to-marketing-menu.pipe';
import { MarketingFeaturedProductMenuComponent } from './components/menus/marketing-menu/marketing-featured-product-menu/marketing-featured-product-menu.component';
import { EmptyMarketingMenuComponent } from './components/menus/marketing-menu/empty-marketing-menu/empty-marketing-menu.component';
import { MenuComponent } from './components/menus/menu/menu.component';
import { MenuFlipperComponent } from './components/menus/menu-flipper/menu-flipper.component';
import { OrientationErrorComponent } from './components/error/orientation-error/orientation-error.component';
import { EmptyMenuFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/empty-menu-footer/empty-menu-footer.component';
import { GetMenuToDisplayPipe } from './pipes/get-menu-to-display.pipe';
import { ScaleDownTextModule } from '../scale-down-text/scale-down-text.module';
import { PageBreakSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/page-break-section/page-break-section.component';
import { MenuHeaderSproutComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-sprout/menu-header-sprout.component';
import { CenteredLogoMenuFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/centered-logo-menu-footer/centered-logo-menu-footer.component';
import { SpotlightMenuComponent } from './components/menus/spotlight-menu/spotlight-menu.component';
import { SpotlightMenuInflatorComponent } from './components/menus/spotlight-menu/inflators/spotlight-menu-inflator/spotlight-menu-inflator.component';
import { CastToSpotlightMenuPipe } from './pipes/cast-to-spotlight-menu.pipe';
import { EmptySectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/empty-section-header/empty-section-header.component';
import { SpotlightHeaderComponent } from './components/menus/spotlight-menu/building-blocks/menu-header/spotlight-header/spotlight-header.component';
import { SpotlightHeaderInflatorComponent } from './components/menus/spotlight-menu/inflators/spotlight-header-inflator/spotlight-header-inflator.component';
import { SpotlightFooterInflatorComponent } from './components/menus/spotlight-menu/inflators/spotlight-footer-inflator/spotlight-footer-inflator.component';
import { SpotlightSectionInflatorComponent } from './components/menus/spotlight-menu/inflators/spotlight-section-inflator/spotlight-section-inflator.component';
import { SpotlightFooterComponent } from './components/menus/spotlight-menu/building-blocks/menu-footer/spotlight-footer/spotlight-footer.component';
import { SpotlightProductSectionComponent } from './components/menus/spotlight-menu/building-blocks/menu-section/spotlight-product-section/spotlight-product-section.component';
import { SpotlightSectionComponent } from './components/menus/spotlight-menu/building-blocks/menu-section/spotlight-section/spotlight-section.component';
import { SpotlightMenuItemInflatorComponent } from './components/menus/spotlight-menu/inflators/spotlight-menu-item-inflator/spotlight-menu-item-inflator.component';
import { SpotlightMenuItemComponent } from './components/menus/spotlight-menu/building-blocks/menu-item/spotlight-menu-item.component';
import { SpotlightProductMenuItemComponent } from './components/menus/spotlight-menu/building-blocks/menu-item/spotlight-product-menu-item/spotlight-product-menu-item.component';
import { CastToSpotLightSectionRowVMsPipe } from './components/menus/spotlight-menu/pipes/cast-to-spot-light-section-row-vms.pipe';
import { EmptySpotlightFooterComponent } from './components/menus/spotlight-menu/building-blocks/menu-footer/empty-spotlight-footer/empty-spotlight-footer.component';
import { SpotlightFooterInflatorContainerDirective } from './components/menus/spotlight-menu/inflators/spotlight-footer-inflator/spotlight-footer-inflator-container.directive';
import { SpotlightMenuInflatorContainerDirective } from './components/menus/spotlight-menu/inflators/spotlight-menu-inflator/spotlight-menu-inflator-container.directive';
import { SpotlightHeaderInflatorContainerDirective } from './components/menus/spotlight-menu/inflators/spotlight-header-inflator/spotlight-header-inflator-container.directive';
import { MarketingComboMenuComponent } from './components/menus/marketing-menu/marketing-combo-menu/marketing-combo-menu.component';
import { MarketingMenuHeaderInflatorComponent } from './components/menus/marketing-menu/inflators/marketing-menu-header-inflator/marketing-menu-header-inflator.component';
import { ProductMenuHeaderComponent } from './components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { ComboHeaderComponent } from './components/menus/marketing-menu/building-blocks/menu-header/combo-header/combo-header.component';
import { MarketingHeaderComponent } from './components/menus/marketing-menu/building-blocks/menu-header/marketing-header.component';
import { CardGridComponent } from './components/menus/marketing-menu/building-blocks/card-grid/card-grid.component';
import { FullImageCardComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/full-image-card/full-image-card.component';
import { HalfImageCardComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/half-image-card/half-image-card.component';
import { EmbeddedImageCardComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/embedded-image-card/embedded-image-card.component';
import { CardNumberComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/card-number/card-number.component';
import { BaseProductCardComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/base-product-card/base-product-card.component';
import { CardPriceComponent } from './components/menus/marketing-menu/building-blocks/menu-cards/card-price/card-price.component';
import { MarketingMenuHeaderInflatorContainerDirective } from './components/menus/marketing-menu/inflators/marketing-menu-header-inflator/marketing-menu-header-inflator-container.directive';
import { IsFullImageCardPipe } from './components/menus/marketing-menu/building-blocks/card-grid/pipes/is-full-image-card.pipe';
import { IsHalfImageCardPipe } from './components/menus/marketing-menu/building-blocks/card-grid/pipes/is-half-image-card.pipe';
import { IsEmbeddedImageCardPipe } from './components/menus/marketing-menu/building-blocks/card-grid/pipes/is-embedded-image-card.pipe';
import { CastToProductCardPipe } from './components/menus/marketing-menu/building-blocks/card-grid/pipes/cast-to-product-card.pipe';
import { FitTextModule } from '../scale-up-text/fit-text.module';
import { MenuHeaderCalyxTrichomesComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-calyx-trichomes/menu-header-calyx-trichomes.component';
import { EmptySectionComponent } from './components/menus/product-menu/building-blocks/menu-section/empty-section/empty-section.component';
import { ProductMenuSectionOverflowCalculatorComponent } from './components/menus/product-menu/overflow-calculators/product-menu-section-overflow-calculator/product-menu-section-overflow-calculator.component';
import { SpotlightMenuSectionOverflowCalculatorComponent } from './components/menus/spotlight-menu/spotlight-menu-section-overflow-calculator/spotlight-menu-section-overflow-calculator.component';
import { MenuHeaderLeftLogoRightDescAndImageComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-logo-desc-image/menu-header-left-logo-right-desc-and-image/menu-header-left-logo-right-desc-and-image.component';
import { MenuHeaderCenteredLogoRightDescAndImageComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-logo-desc-image/menu-header-centered-logo-right-desc-and-image/menu-header-centered-logo-right-desc-and-image.component';
import { MarketingMenuFooterInflatorComponent } from './components/menus/marketing-menu/inflators/marketing-menu-footer-inflator/marketing-menu-footer-inflator.component';
import { MarketingFooterComponent } from './components/menus/marketing-menu/building-blocks/menu-footer/marketing-footer.component';
import { ComboFooterComponent } from './components/menus/marketing-menu/building-blocks/menu-footer/combo-footer/combo-footer.component';
import { MarketingFeaturedCategoryMenuComponent } from './components/menus/marketing-menu/marketing-featured-category-menu/marketing-featured-category-menu.component';
import { FeaturedCategoryHeaderComponent } from './components/menus/marketing-menu/building-blocks/menu-header/featured-category-header/featured-category-header.component';
import { CardSectionNumberComponent } from './components/menus/marketing-menu/building-blocks/card-sections/card-section-number/card-section-number.component';
import { HeaderImageCardSectionComponent } from './components/menus/marketing-menu/building-blocks/card-sections/header-image-card-section/header-image-card-section.component';
import { ProductMenuInflatorComponent } from './components/menus/product-menu/inflators/product-menu-inflator/product-menu-inflator.component';
import { SativaHybridIndicaSplitProductSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-split-product-section.component';
import { MenuHeaderCompanyLogoComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-company-logo/menu-header-company-logo.component';
import { PlantlifeSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/plantlife-section-header/plantlife-section-header.component';
import { PlantlifeSectionSubHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/plantlife-section-header/plantlife-section-sub-header/plantlife-section-sub-header.component';
import { PrintMenuSectionOverflowCalculatorComponent } from './components/menus/print-menu/print-menu-section-overflow-calculator/print-menu-section-overflow-calculator.component';
import { PrintMenuComponent } from './components/menus/print-menu/print-menu.component';
import { IsBaseLevelProductMenuPipe } from './pipes/inflated-as-product-menu.pipe';
import { PrintMenuInflatorComponent } from './components/menus/print-menu/inflators/print-menu-inflator/print-menu-inflator.component';
import { CastToPrintMenuPipe } from './pipes/cast-to-print-menu.pipe';
import { GetPaddingForDisplayContainerFromMenuPipe } from './pipes/get-padding-for-display-container-from-menu.pipe';
import { PlantLifeFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/plant-life-footer/plant-life-footer.component';
import { PlantlifeEmptySectionComponent } from './components/menus/product-menu/building-blocks/menu-section/empty-section/plantlife-empty-section/plantlife-empty-section.component';
import { PlantlifeNonSmokableFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/plantlife-non-smokable-footer/plantlife-non-smokable-footer.component';
import { SplitProductSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/product-section/split-product-section/split-product-section.component';
import { DoubleDutchFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/double-dutch-footer/double-dutch-footer.component';
import { DoubleDutchEmptySectionComponent } from './components/menus/product-menu/building-blocks/menu-section/empty-section/double-dutch-empty-section/double-dutch-empty-section.component';
import { GetArrayElementAtIndexPipe } from './pipes/get-array-element-at-index-pipe';
import { WrappingSwimlaneSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/product-section/wrapping-swimlane-section/wrapping-swimlane-section.component';
import { RangePipe } from './pipes/range.pipe';
import { SwimlaneClassificationAndBadgesLegendFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/swimlane-classification-legend-footer/swimlane-classification-and-badges-legend-footer.component';
import { ComeToBaskSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/come-to-bask-section-header/come-to-bask-section-header.component';
import { DoubleDutchSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/double-dutch-section-header/double-dutch-section-header.component';
import { ClassificationFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/classification-footer/classification-footer.component';
import { GetLastKnownProductSectionForPage } from './pipes/get-last-known-product-section-for-page';
import { MarketingFeaturedCategoryStaticGridMenuComponent } from './components/menus/marketing-menu/marketing-featured-category-static-grid-menu/marketing-featured-category-static-grid-menu.component';
import { CardGridManagerDirective } from './components/menus/directives/card-grid-manager.directive';
import { MenuHeaderTitleSubtitleComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-subtitle/menu-header-title-subtitle.component';
import { HeaderImageCarouselCardSectionComponent } from './components/menus/marketing-menu/building-blocks/card-sections/header-image-carousel-card-section/header-image-carousel-card-section.component';
import { HeaderImageCarouselCardPriceComponent } from './components/menus/marketing-menu/building-blocks/card-sections/header-image-carousel-card-section/header-image-carousel-card-price/header-image-carousel-card-price.component';
import { TypeturaModule } from '../typetura/typetura.module';
import { BadgeLegendFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/badge-legend-footer/badge-legend-footer.component';
import { ProductDealCarouselCardPortraitSectionComponent } from './components/menus/marketing-menu/building-blocks/card-sections/product-deal-carousel-card-section/product-deal-carousel-card-portrait-section/product-deal-carousel-card-portrait-section.component';
import { ProductDealCarouselCardLandscapeSectionComponent } from './components/menus/marketing-menu/building-blocks/card-sections/product-deal-carousel-card-section/product-deal-carousel-card-landscape-section/product-deal-carousel-card-landscape-section.component';
import { MenuHeaderPlantlifeNonSmokableComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-plantlife-non-smokable/menu-header-plantlife-non-smokable.component';
import { PrintMenuEmptyHeaderReplacerInflatorComponent } from './components/menus/product-menu/inflators/product-menu-header-inflator/print-menu-empty-header-replacer-inflator.component';
import { PrintMenuEmptyFooterReplacerInflatorComponent } from './components/menus/product-menu/inflators/product-menu-footer-inflator/print-menu-empty-footer-replacer-inflator.component';
import { MenuHeaderPlantlifeNonSmokableEmptyComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-platlife-non-smokable-empty/menu-header-plantlife-non-smokable-empty.component';
import { PlantlifeNonSmokableEmptyFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/plantlife-non-smokable-footer/plantlife-non-smokable-empty-footer/plantlife-non-smokable-empty-footer.component';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { DoubleDutchEmptyFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/double-dutch-footer/double-dutch-empty-footer/double-dutch-empty-footer.component';
import { ProductMenuStaticSectionOverflowCalculatorComponent } from './components/menus/product-menu/overflow-calculators/product-menu-static-section-overflow-calculator/product-menu-static-section-overflow-calculator.component';
import { ProductSectionWithTransitionsComponent } from './components/menus/product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions.component';
import { RuntzSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/runtz-section-header/runtz-section-header.component';
import { RuntzTitleSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/title-section/runtz-title-section/runtz-title-section.component';
import { SoulBudPrintFooterComponent } from './components/menus/product-menu/building-blocks/menu-footer/soul-bud-print-footer/soul-bud-print-footer/soul-bud-print-footer.component';
import { SpaceMonkeyPrintHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/space-monkey-print-header/space-monkey-print-header.component';
import { PrintMenuSectionsContainerComponent } from './components/menus/print-menu/print-menu-sections-container/print-menu-sections-container.component';
import { EmptyDisplayComponent } from './components/display/empty-display/empty-display.component';
import { MarketingSmartPlaylistMenuComponent } from './components/menus/marketing-menu/marketing-product-linked-looping-menu/marketing-smart-playlist-menu.component';
import { PlantlifeNonSmokableEmptySectionComponent } from './components/menus/product-menu/building-blocks/menu-section/empty-section/plantlife-non-smokable-empty-section/plantlife-non-smokable-empty-section.component';
import { AssetSectionAlignmentPipe } from './components/menus/product-menu/building-blocks/menu-section/image-section/asset-section-alignment.pipe';
import { SectionWrapperClassPipe } from './components/menus/product-menu/building-blocks/menu-section/image-section/section-wrapper-class.pipe';
import { SectionBodyTextColorPipe } from './components/menus/product-menu/building-blocks/menu-section/image-section/section-body-text-color.pipe';
import { AssetSectionRepetitionsPipe } from './components/menus/product-menu/building-blocks/menu-section/image-section/asset-section-repetitions.pipe';
import { AssetSectionWidthPipe } from './components/menus/product-menu/building-blocks/menu-section/image-section/asset-section-width.pipe';
import { IsPageBreakSectionPipe } from './components/menus/pipes/is-page-break-section.pipe';
import { IsEmptySectionPipe } from './components/menus/pipes/is-empty-section.pipe';
import { SectionsOnPagePipe } from './components/menus/pipes/sections-on-page.pipe';
import { IsLineItemOddPipe } from './components/menus/pipes/is-line-item-odd.pipe';
import { GetKeyedSectionRowViewModelsPipe } from './components/menus/pipes/get-keyed-section-row-view-models.pipe';
import { IsProductPageVisiblePipe } from './components/menus/pipes/is-product-data-visible.pipe';
import { FeaturedProductDescriptionComponent } from './components/menus/marketing-menu/marketing-featured-product-menu/components/featured-product-description/featured-product-description.component';
import { RenderPrintMenuLiveViewComponent } from './components/menus/render-print-menu-live-view/render-print-menu-live-view.component';
import { MenuHeaderPlantlifeComponent } from './components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-plantlife/menu-header-plantlife.component';
import { ReportNewProductsSectionComponent } from './components/menus/product-menu/building-blocks/menu-section/report-section/new-products-section/report-new-products-section.component';
import { ReportNewProductSectionItemComponent } from './components/menus/product-menu/building-blocks/menu-item/report-new-product-section-item/report-new-product-section-item.component';
import { OrderReviewSectionHeaderComponent } from './components/menus/product-menu/building-blocks/menu-section-header/order-review-section-header/order-review-section-header.component';
import { NgBooleanPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { SwimlaneSectionInflatorComponent } from './components/menus/product-menu/inflators/swimlane-section-inflator/swimlane-section-inflator.component';
import { GetClassificationSymbolPipe } from './components/menus/pipes/get-classification-symbol.pipe';
import { GetDefaultBackgroundImageUrlPipe } from './components/menus/pipes/get-default-background-image-url.pipe';
import { GetDefaultBackgroundVideoUrlPipe } from './components/menus/pipes/get-default-background-video-url.pipe';
import { GetThemeClassPipe } from './components/menus/pipes/get-theme-class.pipe';
import { GetSectionBorderColorPipe } from './pipes/get-section-border-color.pipe';
import { GetSectionBorderRadiusPipe } from './pipes/get-section-border-radius.pipe';
import { GetSectionBottomBorderPipe } from './pipes/get-section-bottom-border.pipe';
import { GetSectionBottomBorderHeightInPxPipe } from './pipes/get-section-bottom-border-height-in-px.pipe';
import { RxFor } from '@rx-angular/template/for';
import { InflatorModule } from '../inflator/inflator.module';
import { IsProductPageHiddenPipe } from './components/menus/pipes/is-product-page-hidden.pipe';
import { GetSectionAnimationStatePipe } from './components/menus/pipes/get-section-animation-state.pipe';
import { GetSectionScrollAnimationStatePipe } from './components/menus/pipes/get-section-scroll-animation-state.pipe';
import { MarketingUrlPlaylistMenuComponent } from './components/menus/marketing-menu/marketing-url-playlist-menu/marketing-url-playlist-menu.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ColumnCannabinoidComponent } from '../shared/components/column/column-cannabinoid/column-cannabinoid.component';
import { ColumnTerpeneComponent } from '../shared/components/column/column-terpene/column-terpene.component';
import { ProductSectionItemPlantlifeComponent } from './components/menus/product-menu/building-blocks/menu-item/product-section-item-plantlife/product-section-item-plantlife.component';
import { IsCannabinoidColumnPipe } from './components/menus/pipes/is-cannabinoid-column.pipe';
import { IsTerpeneColumnPipe } from './components/menus/pipes/is-terpene-column.pipe';

@NgModule({
  declarations: [
    ProductMenuComponent,
    NotFoundComponent,
    SplashComponent,
    DisplayComponent,
    ProductMenuFooterComponent,
    ProductMenuFooterInflatorComponent,
    ProductMenuSectionInflatorComponent,
    ProductMenuHeaderInflatorComponent,
    ProductMenuFooterInflatorContainerDirective,
    TitleSectionComponent,
    AssetSectionComponent,
    ProductSectionHeaderComponent,
    ProductSectionItemComponent,
    ProductSectionItemPlantlifeComponent,
    MenuHeaderTitleImageComponent,
    MenuFooterOvalBoarderComponent,
    MenuHeaderTitleImageWithBackgroundAssetComponent,
    MenuHeaderTitleComponent,
    MenuHeaderPlainJaneComponent,
    CastToProductMenuPipe,
    ProductMenuItemInflatorComponent,
    MenuItemComponent,
    ProductMenuSectionHeaderInflatorComponent,
    ProductMenuSectionHeaderInflatorContainerDirective,
    MenuHeaderPreRollComponent,
    MarketingMenuInflatorComponent,
    MarketingLoopComponent,
    CastToMarketingMenuPipe,
    MarketingFeaturedProductMenuComponent,
    FeaturedProductDescriptionComponent,
    EmptyMarketingMenuComponent,
    MenuComponent,
    MenuFlipperComponent,
    OrientationErrorComponent,
    EmptyMenuFooterComponent,
    GetMenuToDisplayPipe,
    GetLastKnownProductSectionForPage,
    PageBreakSectionComponent,
    MenuHeaderSproutComponent,
    CenteredLogoMenuFooterComponent,
    SpotlightMenuComponent,
    SpotlightMenuInflatorComponent,
    CastToSpotlightMenuPipe,
    EmptySectionHeaderComponent,
    SpotlightMenuItemComponent,
    SpotlightHeaderComponent,
    SpotlightHeaderInflatorComponent,
    SpotlightFooterInflatorComponent,
    SpotlightSectionInflatorComponent,
    SpotlightFooterComponent,
    SpotlightProductSectionComponent,
    SpotlightSectionComponent,
    SpotlightMenuItemInflatorComponent,
    SpotlightProductMenuItemComponent,
    CastToSpotLightSectionRowVMsPipe,
    EmptySpotlightFooterComponent,
    SpotlightFooterInflatorContainerDirective,
    SpotlightMenuInflatorContainerDirective,
    SpotlightHeaderInflatorContainerDirective,
    MarketingComboMenuComponent,
    MarketingHeaderComponent,
    MarketingMenuHeaderInflatorComponent,
    ProductMenuHeaderComponent,
    ComboHeaderComponent,
    CardGridComponent,
    FullImageCardComponent,
    HalfImageCardComponent,
    EmbeddedImageCardComponent,
    CardNumberComponent,
    BaseProductCardComponent,
    CardPriceComponent,
    MarketingMenuHeaderInflatorContainerDirective,
    IsFullImageCardPipe,
    IsHalfImageCardPipe,
    IsEmbeddedImageCardPipe,
    CastToProductCardPipe,
    MenuHeaderCalyxTrichomesComponent,
    MenuHeaderCenteredLogoRightDescAndImageComponent,
    ProductMenuSectionOverflowCalculatorComponent,
    EmptySectionComponent,
    SpotlightMenuSectionOverflowCalculatorComponent,
    MenuHeaderLeftLogoRightDescAndImageComponent,
    MarketingMenuFooterInflatorComponent,
    MarketingFooterComponent,
    ComboFooterComponent,
    HeaderImageCardSectionComponent,
    MarketingFeaturedCategoryMenuComponent,
    FeaturedCategoryHeaderComponent,
    CardSectionNumberComponent,
    ProductMenuInflatorComponent,
    SativaHybridIndicaSplitProductSectionComponent,
    MenuHeaderCompanyLogoComponent,
    PlantlifeSectionHeaderComponent,
    PlantlifeSectionSubHeaderComponent,
    PrintMenuSectionOverflowCalculatorComponent,
    PrintMenuComponent,
    IsBaseLevelProductMenuPipe,
    PrintMenuInflatorComponent,
    CastToPrintMenuPipe,
    GetPaddingForDisplayContainerFromMenuPipe,
    PlantLifeFooterComponent,
    PlantlifeEmptySectionComponent,
    IsPageBreakSectionPipe,
    IsEmptySectionPipe,
    PlantlifeNonSmokableFooterComponent,
    SplitProductSectionComponent,
    DoubleDutchFooterComponent,
    DoubleDutchEmptySectionComponent,
    GetArrayElementAtIndexPipe,
    WrappingSwimlaneSectionComponent,
    RangePipe,
    SwimlaneClassificationAndBadgesLegendFooterComponent,
    ComeToBaskSectionHeaderComponent,
    SectionsOnPagePipe,
    ClassificationFooterComponent,
    DoubleDutchSectionHeaderComponent,
    MarketingFeaturedCategoryStaticGridMenuComponent,
    CardGridManagerDirective,
    MenuHeaderTitleSubtitleComponent,
    HeaderImageCarouselCardSectionComponent,
    HeaderImageCarouselCardPriceComponent,
    BadgeLegendFooterComponent,
    ProductDealCarouselCardPortraitSectionComponent,
    ProductDealCarouselCardLandscapeSectionComponent,
    MenuHeaderPlantlifeNonSmokableComponent,
    PrintMenuEmptyHeaderReplacerInflatorComponent,
    PrintMenuEmptyFooterReplacerInflatorComponent,
    MenuHeaderPlantlifeNonSmokableEmptyComponent,
    PlantlifeNonSmokableEmptyFooterComponent,
    FilterListPipe,
    DoubleDutchEmptyFooterComponent,
    ProductMenuStaticSectionOverflowCalculatorComponent,
    IsLineItemOddPipe,
    ProductSectionWithTransitionsComponent,
    GetKeyedSectionRowViewModelsPipe,
    IsProductPageVisiblePipe,
    RuntzSectionHeaderComponent,
    RuntzTitleSectionComponent,
    SoulBudPrintFooterComponent,
    SpaceMonkeyPrintHeaderComponent,
    PrintMenuSectionsContainerComponent,
    EmptyDisplayComponent,
    MarketingSmartPlaylistMenuComponent,
    PlantlifeNonSmokableEmptySectionComponent,
    AssetSectionAlignmentPipe,
    SectionWrapperClassPipe,
    SectionBodyTextColorPipe,
    AssetSectionRepetitionsPipe,
    AssetSectionWidthPipe,
    RenderPrintMenuLiveViewComponent,
    MenuHeaderPlantlifeComponent,
    ReportNewProductsSectionComponent,
    ReportNewProductSectionItemComponent,
    OrderReviewSectionHeaderComponent,
    SwimlaneSectionInflatorComponent,
    GetClassificationSymbolPipe,
    GetDefaultBackgroundImageUrlPipe,
    GetDefaultBackgroundVideoUrlPipe,
    GetThemeClassPipe,
    GetSectionBorderColorPipe,
    GetSectionBorderRadiusPipe,
    GetSectionBottomBorderPipe,
    GetSectionBottomBorderHeightInPxPipe,
    IsProductPageHiddenPipe,
    GetSectionAnimationStatePipe,
    GetSectionScrollAnimationStatePipe,
    MarketingUrlPlaylistMenuComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DisplayRoutingModule,
    NgxSpinnerModule,
    NgStringPipesModule,
    ScaleDownTextModule,
    FlexLayoutModule,
    NgBooleanPipesModule,
    FitTextModule,
    TypeturaModule,
    RxFor,
    InflatorModule,
    ColumnCannabinoidComponent,
    ColumnTerpeneComponent,
    IsCannabinoidColumnPipe,
    IsTerpeneColumnPipe
  ]
})
export class DisplayModule {
}
