import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnTerpeneType, SectionColumnType } from '../product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'isTerpeneColumn',
  standalone: true
})
export class IsTerpeneColumnPipe implements PipeTransform {

  transform(columnType: SectionColumnType): SectionColumnTerpeneType | null {
    const isTerpene = Object.values(SectionColumnTerpeneType)
      .includes(columnType as SectionColumnTerpeneType);
    return isTerpene ? columnType as SectionColumnTerpeneType : null;
  }

}
