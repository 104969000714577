// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { ProductSectionViewModel } from '../../../product-menu/building-blocks/menu-section/product-section/product-section-view-model';
import { DisplayDomainModel } from '../../../../../../../domain/display-domain-model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { NumberUtils } from '../../../../../../../utils/number.utils';
import { UnitOfMeasure } from '../../../../../../../models/enum/dto/unit-of-measure.enum';
import { ImageAPI } from '../../../../../../../api/image-api';
import { ColorUtils } from '../../../../../../../utils/color-utils';
import { SectionRowViewModelFeaturedCategory } from '../../../product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-featured-category';
import { DisplayMenuCoupling } from '../../../../../../../couplings/display-menu-coupling.service';
import { VariantAssetService } from '../../../../../../services/variant-asset-service';

@Injectable()
export class FeaturedCategoryCardViewModel extends ProductSectionViewModel {

  constructor(
    protected domainModel: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected imageAPI: ImageAPI,
    protected variantAssetService: VariantAssetService
  ) {
    super(domainModel, displayMenuCoupling);
  }

  public featureCategorySectionRowViewModels$ = this.sectionRowViewModels$.pipe(
    map(sectionRowViewModels => sectionRowViewModels as SectionRowViewModelFeaturedCategory[])
  );

  private _updateCurrentCarouselPosition: Subject<any> = new Subject<any>();
  private _currentCarouselPosition: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentCarouselPosition$ = this._currentCarouselPosition.pipe(distinctUntilChanged());

  private _reset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private reset$ = this._reset as Observable<boolean>;

  public sectionRowViewModelCount$ = this.featureCategorySectionRowViewModels$.pipe(
    map(secRowVMs => secRowVMs?.length)
  );

  public isLandscape$ = this.menu$.pipe(map(menu => menu?.isLandscape()));
  public isPortrait$ = this.menu$.pipe(map(menu => menu?.isPortrait()));

  public sectionBodyTextColor$ = this.menu$.pipe(
    switchMap(menu => this.section$.pipe(map(section => menu?.getSectionBodyTextColor(section))))
  );

  public priceColor$ = combineLatest([
    this.section$,
    this.sectionBodyTextColor$
  ]).pipe(
    map(([section, sectionBodyTextColor]) => {
      return section?.metadata?.productsHeaderBackgroundColor || sectionBodyTextColor;
    })
  );
  public sectionHeaderTextColor$ = this.menu$.pipe(
    switchMap(menu => this.section$.pipe(map(section => menu?.getSectionHeaderTextColor(section))))
  );

  private updateCarouselPosition = this.sectionRowViewModelCount$.pipe(
    switchMap(secRowVMCount => {
      return this._updateCurrentCarouselPosition.pipe(
        filter(position => position !== false),
        map(position => position % secRowVMCount)
      );
    }),
    startWith(0),
    takeUntil(this.onDestroy)
  ).subscribe(this._currentCarouselPosition);

  public currentCarouselProductRowViewModel$ = combineLatest([
    this.featureCategorySectionRowViewModels$,
    this.currentCarouselPosition$
  ]).pipe(
    map(([secRowVM, ccPos]) => secRowVM?.[ccPos])
  );

  public backgroundColor$ = combineLatest([
    this.section$,
    this.currentCarouselProductRowViewModel$
  ]).pipe(
    map(([section, productRowViewModel]) => {
      const colorSource = productRowViewModel?.style?.backgroundColor
        || section?.metadata?.productsContainerBackgroundColor
        || '#ffffff';
      const opacity = Number(section?.metadata?.cardOpacity || '1.0');
      const [R, G, B] = ColorUtils.hexToRgb(colorSource);
      return `rgba(${R}, ${G}, ${B}, ${opacity})`;
    })
  );

  public forcedRowBoldStyling$ = this.currentCarouselProductRowViewModel$.pipe(
    map(section => {
      return section?.style?.bold;
    })
  );

  public forcedRowItalicStyling$ = this.currentCarouselProductRowViewModel$.pipe(
    map(section => {
      return section?.style?.italics;
    })
  );

  public originalPrice$ = combineLatest([
    this.currentCarouselProductRowViewModel$,
    this.menu$,
    this.locationConfig$,
  ]).pipe(
    map(([productRowViewModel, menu, locationConfig]) => {
      const locationId = menu?.locationId;
      const companyId = menu?.companyId;
      const priceFormat = locationConfig?.priceFormat;
      return productRowViewModel.rowVariants
        ?.firstOrNull()
        ?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    })
  );

  public hideSalePrices$ = this.menu$.pipe(map(menu => menu?.menuOptions?.hideSale || false));

  public discountedPrice$ = combineLatest([
    this.currentCarouselProductRowViewModel$,
    this.menu$,
    this.locationConfig$,
    this.originalPrice$,
    this.hideSalePrices$,
  ]).pipe(
    map(([productRowViewModel, menu, locationConfig, originalPrice, hideSalePrice]) => {
      const locationId = menu?.locationId;
      const companyId = menu?.companyId;
      const priceFormat = locationConfig?.priceFormat;
      const discountedPrice = productRowViewModel.rowVariants
        ?.firstOrNull()
        ?.getDiscountedPriceOrNull(locationId, companyId, priceFormat);
      return hideSalePrice ? null : discountedPrice;
    })
  );

  public discountedPriceNullCoalesceOriginalPrice$ = combineLatest([
    this.discountedPrice$,
    this.originalPrice$,
    this.hideSalePrices$
  ]).pipe(
    map(([discountedPrice, originalPrice, hideSalePrices]) => {
      return hideSalePrices ? originalPrice : (discountedPrice ?? originalPrice);
    })
  );

  public currentDiscount$ = combineLatest([
    this.originalPrice$,
    this.discountedPrice$
  ]).pipe(
    map(([originalPrice, discountedPrice]) => {
      return !discountedPrice ? null : (originalPrice - discountedPrice);
    }),
    map(discount => NumberUtils.roundToTwoDecimalPlaces(discount))
  );
  public showDiscountTitle$ = this.currentDiscount$.pipe(map(discount => !!discount));

  public currentBrand$ = this.currentCarouselProductRowViewModel$.pipe(
    map(productRowViewModel => productRowViewModel.getBrand())
  );

  public subtitle$ = combineLatest([
    this.section$,
    this.currentBrand$
  ]).pipe(
    map(([section, brand]) => section?.subTitle || brand)
  );

  public productName$ = this.currentCarouselProductRowViewModel$.pipe(
    map(productRowViewModel => productRowViewModel?.getRowTitle())
  );

  public brand$ = this.currentCarouselProductRowViewModel$.pipe(
    map(productRowViewModel => productRowViewModel?.getBrand())
  );

  public size$ = this.currentCarouselProductRowViewModel$.pipe(
    map(productRowViewModel => {
      const variant = productRowViewModel?.rowVariants[0];
      let sizeText;
      if (variant?.packagedQuantity > 1 && variant?.unitSize > 0 && variant?.unitOfMeasure !== UnitOfMeasure.NA) {
        // multi unit pack
        const packagedQuantityText = String(variant.packagedQuantity + ' x ');
        sizeText = String(packagedQuantityText + variant.unitSize + variant.unitOfMeasure);
      } else {
        // single unit pack
        if (variant?.unitSize > 0 && variant?.unitOfMeasure !== UnitOfMeasure.NA) {
          sizeText = String(variant.unitSize + variant.unitOfMeasure);
        }
      }
      return sizeText;
    })
  );

  public badges$ = this.currentCarouselProductRowViewModel$.pipe(
    map(productRowViewModel => productRowViewModel?.getAllVariantBadges())
  );

  public sectionImage$ = this.section$.pipe(map(section => section?.image));
  public secondaryImage$ = this.section$.pipe(map(section => section?.secondaryImage));

  public cardCount$ = this.menu$.pipe(map(menu => menu?.metadata?.cardCount));
  public showLabel$ = this.menu$.pipe(map(menu => !menu.menuOptions?.hideLabel));
  public transitionType$ = this.menu$.pipe(map(menu => menu?.overflowState));

  connectToUpdateCurrentCarouselPosition = (update: any) => this._updateCurrentCarouselPosition.next(update);
  connectToReset = (reset: boolean) => this._reset.next(reset);

}
